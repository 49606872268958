.navbutton {
  height: 4.5rem;
  border: 0;
  width: 11.8rem;
  padding: 1rem;
  background: #f8f9fc;
  font-family: inherit;
  border: 1px solid #1b1a41;
  -webkit-border-radius: 1.2rem;
  -moz-border-radius: 1.2rem;
  -ms-border-radius: 1.2rem;
  -o-border-radius: 1.2rem;
  cursor: pointer;
  font-size: 1.5rem;
  color: #1b1a41;
}

.button {
  width: auto;
  border: 0;
  height: 5rem;
  border-radius: 1.2rem;
  background: #1b1a41;
  -webkit-border-radius: 1.2rem;
  -moz-border-radius: 1.2rem;
  -ms-border-radius: 1.2rem;
  -o-border-radius: 1.2rem;
  font-family: inherit;
  color: #fff;
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  padding: 0 1.2rem;
  margin: 0 auto;
  margin-top: 7.7rem;
  cursor: pointer;
  position: relative;
  z-index: 999;
}
