.faq {
  max-width: 144rem;
  margin: 0 auto;
  padding: 8rem 12rem;
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 1024px) {
    padding: 8.2rem 8rem;
  }

  @media only screen and (max-width: 768px) {
    padding: 8.2rem 5rem;
  }

  @media only screen and (max-width: 520px) {
    padding: 7rem 3rem;
    flex-direction: column;
    gap: 5rem;
  }

  &_div1 {
    & > h2 {
      color: #1b1a41;
      font-family: "Space Grotesk";
      font-size: 4.2rem;
      font-weight: 600;
      width: 39.1rem;

      @media only screen and (max-width: 768px) {
        font-size: 3.5rem;
        width: 36rem;
      }

      @media only screen and (max-width: 520px) {
        font-size: 3.5rem;
        width: 100%;
        text-align: center;
      }
    }

    & > p {
      color: #546881;
      font-size: 2rem;
      width: 15rem;
      margin-top: 1.4rem;

      @media only screen and (max-width: 520px) {
        width: 100%;
        text-align: center;
      }
    }
  }

  &_div2 {
    width: 61.4rem;
    height: auto;

    @media only screen and (max-width: 520px) {
      width: 100%;
    }
  }
}

.accordion {
  //   width: 90%;
  //   max-width: 1000px;
  //   margin: 2rem auto;
}

.accordion_item {
  border-radius: 1rem;
  background: #f8f9fc;
  color: #1b1a41;
  border-radius: 0.5rem;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.25);
  padding: 2rem 2rem;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  -ms-border-radius: 1rem;
  -o-border-radius: 1rem;
  color: #858699;
  font-size: 1.5rem;
  margin-bottom: 2rem;
}

.accordion_item_header {
  min-height: 3.5rem;
  line-height: 1.25rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  color: #1b1a41;
  font-size: 2rem;
}

.accordion_item_header::after {
  content: "\002B";
  font-size: 2rem;
  position: absolute;
  right: 1rem;
}

.accordion_item_header.active::after {
  content: "\2212";
}

.accordion_item_body {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  font-size: 1.6rem;
}

.accordion_item_body_content {
  margin-top: 2rem;
  padding-top: 2rem;
  border-top: 1px solid;
  border-image: linear-gradient(to right, transparent, #1b1a41, transparent) 1;
  letter-spacing: 0.5px;
  line-height: 2rem;
}

/* End of Accordion styling */
.faq_accordion {
  width: 61.4rem;
  height: auto;
  margin: 0 auto;

  @media only screen and (max-width: 1024px) {
    width: 100%;
  }
}
