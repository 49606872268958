.navbar {
  height: 7.2rem;
  width: 126.2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2.6rem;
  border-radius: 1.2rem;
  border: 1px solid rgba(223, 224, 227, 0.5);
  background: rgba(248, 249, 252, 0.2);
  backdrop-filter: blur(5px);
  -webkit-border-radius: 1.2rem;
  -moz-border-radius: 1.2rem;
  -ms-border-radius: 1.2rem;
  -o-border-radius: 1.2rem;
  margin: 0 auto;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99999999;

  @media only screen and (max-width: 520px) {
    padding: 0 1.5rem;
  }

  & > img {
    height: 3.1rem;
    width: 12.8rem;
    cursor: pointer;
  }

  @media only screen and (max-width: 1200px) {
    width: 115rem;
  }

  @media only screen and (max-width: 1024px) {
    width: 100rem;
  }

  @media only screen and (max-width: 768px) {
    width: 75rem;
  }

  @media only screen and (max-width: 520px) {
    width: 90%;
  }
}
