.footer {
  max-width: 144rem;
  width: 100%;
  margin: 0 auto;
  padding: 8.7rem 12rem;

  @media only screen and (max-width: 1024px) {
    padding: 8.7rem 8rem;
  }

  @media only screen and (max-width: 768px) {
    padding: 8.7rem 5rem;
  }

  @media only screen and (max-width: 520px) {
    padding: 7rem 3rem;
  }

  &_img {
    border-radius: 2rem;
    background: #1b1a41;
    -webkit-border-radius: 2rem;
    -moz-border-radius: 2rem;
    -ms-border-radius: 2rem;
    -o-border-radius: 2rem;
    padding: 5.5rem 0rem;

    & > h2 {
      text-align: center;
      font-size: 4.2rem;
      font-family: "Space Grotesk", sans-serif;
      width: 53rem;
      margin: 0 auto;
      background: linear-gradient(180deg, #fefeff 9.51%, #1b1a41 183.98%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      @media only screen and (max-width: 768px) {
        width: 100%;
      }

      @media only screen and (max-width: 520px) {
        font-size: 3.2rem;
      }
    }

    & > p {
      text-align: center;
      color: rgba(254, 254, 255, 0.5);
      font-size: 2rem;
      line-height: 2.7rem;
    }

    & > button {
      border: 0;
      width: 16.4rem;
      display: block;
      margin: 0 auto;
      padding: 10px 0;
      border-radius: 1.2rem;
      height: 5rem;
      background: #fefeff;
      -webkit-border-radius: 1.2rem;
      -moz-border-radius: 1.2rem;
      -ms-border-radius: 1.2rem;
      -o-border-radius: 1.2rem;
      margin-top: 4.5rem;
      font-size: 2rem;
      font-family: inherit;
      cursor: pointer;
      color: #1b1a41;
    }
  }

  &_navmain {
    max-width: 144rem;
    width: 100%;
    margin: 0 auto;
    padding: 4.2rem 12rem;
    padding-bottom: 0;
    position: relative;

    @media only screen and (max-width: 1024px) {
      padding: 4.2rem 8rem;
    }

    @media only screen and (max-width: 768px) {
      padding: 4.2rem 5rem;
    }

    @media only screen and (max-width: 520px) {
      padding: 4.2rem 3rem;
    }
  }

  &_nav {
    display: flex;
    width: 100%;
    height: 3rem;
    justify-content: space-between;

    &_hr {
      display: block;
      border: 0.7px solid #dfe0e3;
      margin-top: 3rem;
    }
  }

  &_navitems {
    display: flex;
    gap: 1.5rem;
  }

  &_bigtext {
    font-size: 20.5rem;
    margin-top: 8rem;
    font-weight: 600;
    color: blue;
    background: linear-gradient(
      182deg,
      rgba(7, 6, 18, 0.2) 24.77%,
      rgba(255, 255, 255, 0.2) 89.04%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Space Grotesk", sans-serif;
    text-align: center;

    @media only screen and (max-width: 768px) {
      font-size: 15rem;
      width: 100%;
    }

    @media only screen and (max-width: 520px) {
      font-size: 8rem;
    }
  }

  &_cont {
    width: 100%;
  }
}
