.header {
  height: 133.6rem;
  width: 100%;
  background-color: #f8f9fc;
  background-image: url("./../../assets/Dot\ grid.png");
  background-size: contain;

  @media only screen and (max-width: 768px) {
    height: auto;
  }

  &_main {
    max-width: 144rem;
    width: 100%;
    height: auto;
    margin: 0 auto;
    position: relative;
    padding-top: 3.6rem;
  }

  &_herotext {
    width: 89.6rem;
    height: auto;
    margin: 0 auto;
    margin-top: 15rem;

    @media only screen and (max-width: 768px) {
      width: 100%;
      padding: 0rem 6rem;
    }

    @media only screen and (max-width: 520px) {
      width: 100%;
      padding: 0rem 3rem;
    }

    & > h1 {
      color: #1b1a41;
      text-align: center;
      font-size: 5rem;
      font-weight: 600;
      letter-spacing: 1px;

      @media only screen and (max-width: 520px) {
        font-size: 4rem;
      }
    }

    & > small {
      display: block;
      text-align: center;
      font-size: 2rem;
      font-weight: 400;
      margin-top: 2rem;
    }
  }

  &_asset1 {
    position: absolute;
    right: 0;
    top: 45rem;

    @media only screen and (max-width: 520px) {
      width: 16rem;
    }
  }

  &_asset2 {
    position: absolute;
    left: 0;
    top: 47rem;

    @media only screen and (max-width: 520px) {
      width: 16rem;
    }
  }

  &_heroimg {
    height: 81.7rem;
    width: 103rem;
    margin: 0 auto;
    margin-top: 10.3rem;

    @media only screen and (max-width: 768px) {
      width: 100%;
      height: auto;
    }

    & > img {
      width: 103rem;

      @media only screen and (max-width: 1024px) {
        width: 100%;
        z-index: 999;
        position: relative;
      }

      @media only screen and (max-width: 768px) {
        padding: 0rem 3rem;
      }
    }
  }
}
