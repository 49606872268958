.works {
  max-width: 144rem;
  margin: 0 auto;
  padding: 8.2rem 16rem;

  @media only screen and (max-width: 1024px) {
    padding: 8.2rem 8rem;
  }

  @media only screen and (max-width: 768px) {
    padding: 8.2rem 5rem;
  }

  @media only screen and (max-width: 520px) {
    padding: 7rem 3rem;
  }

  & > h2 {
    text-align: center;
    color: #1b1a41;
    font-family: "Space Grotesk";
    font-size: 4.2rem;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.824px;
    margin-bottom: 2.4rem;

    @media only screen and (max-width: 520px) {
      margin-bottom: 4rem;
    }
  }

  &_div {
    display: flex;
    align-items: center;
    gap: 7.1rem;

    @media only screen and (max-width: 520px) {
      flex-direction: column;
    }
  }
}

.work {
  height: 39rem;
  width: 32.1rem;
  position: relative;
  padding-left: 2.9rem;
  padding-top: 7.5rem;

  @media only screen and (max-width: 1200px) {
    width: 100%;
    height: auto;
  }

  &_number {
    font-family: "Space Grotesk";
    font-size: 10rem;
    font-weight: 600;
    letter-spacing: 0.2rem;
    background: linear-gradient(
      180deg,
      rgba(84, 104, 129, 0.2) 0%,
      rgba(18, 22, 27, 0) 100%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
  }

  &_img {
    width: 29.2rem;
    height: 23.2rem;
    border-radius: 1.2rem;
    -webkit-border-radius: 1.2rem;
    -moz-border-radius: 1.2rem;
    -ms-border-radius: 1.2rem;
    -o-border-radius: 1.2rem;
    z-index: 9999;
    position: relative;

    @media only screen and (max-width: 1200px) {
      width: 100%;
      height: auto;
    }
  }

  &_title {
    color: #1b1a41;
    font-size: 2rem;
    font-weight: 600;
    letter-spacing: 0.4px;
    margin-top: 1.5rem;
  }

  &_small {
    color: #858699;
    font-size: 1.5rem;
    font-weight: 400;
    letter-spacing: 0.3px;
  }
}
