.features {
  width: 100%;
  height: auto;
  background: #fefeff;

  &_sect1 {
    max-width: 144rem;
    margin: 0 auto;
    display: flex;
    gap: 9.6rem;
    position: relative;
    padding: 10rem 12rem;

    @media only screen and (max-width: 1024px) {
      align-items: center;
      padding: 6.6rem;
    }

    @media only screen and (max-width: 768px) {
      padding: 6.6rem 5rem;
    }

    @media only screen and (max-width: 520px) {
      flex-direction: column;
      align-items: start;
      padding: 7rem 3rem;
    }

    &_floattext {
      height: 16rem;
      width: 25rem;
      background-image: url("./../../assets/Modern.png");
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: -6rem;
      left: -2rem;
      z-index: 1;

      & > div {
        width: auto;
        padding: 0 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 3.7rem;
        border-radius: 3.5rem;
        border: 1px solid rgba(223, 224, 227, 0.5);
        background: rgba(248, 249, 252, 0.1);
        backdrop-filter: blur(2px);
        -webkit-border-radius: 3.5rem;
        -moz-border-radius: 3.5rem;
        -ms-border-radius: 3.5rem;
        -o-border-radius: 3.5rem;
        gap: 0.5rem;

        & > p {
          font-size: 1.5rem;
        }
      }
    }

    &_text {
      height: 100%;
      width: 56.1rem;
      position: relative;

      @media only screen and (max-width: 1024px) {
        width: 40rem;
      }

      @media only screen and (max-width: 520px) {
        width: 100%;
      }

      & > h2 {
        color: #1d242d;
        font-size: 4.2rem;
        font-weight: 600;
        letter-spacing: 0.0824rem;
        position: relative;
        z-index: 999;
        margin-top: 8rem;
        font-family: "Space Grotesk";

        @media only screen and (max-width: 520px) {
          font-size: 3.5rem;
        }
      }

      & > p {
        font-size: 2rem;
        margin-top: 2rem;
        line-height: 2.7rem;
        font-weight: 400;
        width: 50rem;

        @media only screen and (max-width: 1024px) {
          width: 40rem;
        }

        @media only screen and (max-width: 520px) {
          width: 100%;
          font-size: 1.6rem;
        }
      }
    }

    &_img {
      height: auto;

      & > img {
        width: 54.4rem;

        @media only screen and (max-width: 1200px) {
          width: 100%;
        }
      }
    }
  }

  &_sect2 {
    display: flex;
    flex-direction: row-reverse;

    @media only screen and (max-width: 520px) {
      flex-direction: column;
    }
  }

  &_sect3 {
    max-width: 144rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    position: relative;
    padding: 10rem 12rem;

    @media only screen and (max-width: 1024px) {
      padding: 10rem 8rem;
    }

    @media only screen and (max-width: 1024px) {
      padding: 10rem 5rem;
    }

    @media only screen and (max-width: 520px) {
      padding: 7rem 3rem;
    }

    &_text {
      width: 100%;
      height: 13.3rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media only screen and (max-width: 520px) {
        flex-direction: column;
        align-items: flex-start;
        height: auto;
      }

      & > h2 {
        color: #1b1a41;
        font-family: "Space Grotesk";
        font-size: 4.2rem;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.824px;

        @media only screen and (max-width: 520px) {
          margin-bottom: 2rem;
        }
      }

      & > p {
        width: 53.4rem;
        font-size: 2rem;
        font-weight: 400;
        line-height: 2.7rem;

        @media only screen and (max-width: 520px) {
          margin-bottom: 2rem;
          width: 100%;
          font-size: 1.6rem;
        }
      }
    }

    &_img {
      width: 100%;
      height: 24.9rem;

      @media only screen and (max-width: 520px) {
        height: auto;
      }

      & > img {
        width: 120.1rem;
        margin: 0 auto;
        @media only screen and (max-width: 1200px) {
          width: 100%;
        }
      }
    }
  }

  &_sect4 {
    max-width: 144rem;
    margin: 0 auto;
    display: flex;
    gap: 3rem;
    position: relative;
    overflow: hidden;

    @media only screen and (max-width: 520px) {
      flex-direction: column;
    }

    &_text {
      width: 100%;
      height: 13.3rem;
      align-items: center;
      padding: 13.7rem 3.8rem 30.9rem 12rem;

      @media only screen and (max-width: 1024px) {
        padding: 13.7rem 3.8rem 30.9rem 8rem;
      }

      @media only screen and (max-width: 768px) {
        padding: 7rem 5rem 30.9rem 5rem;
      }

      @media only screen and (max-width: 520px) {
        height: auto;
        padding: 8.7rem 3rem 3rem 3rem;
      }

      & > h2 {
        color: #1b1a41;
        font-family: "Space Grotesk";
        font-size: 4.2rem;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.824px;
        width: 56.2rem;
        margin-bottom: 2.4rem;

        @media only screen and (max-width: 1024px) {
          width: 45rem;
        }

        @media only screen and (max-width: 520px) {
          width: 100%;
        }
      }

      & > p {
        width: 53.4rem;
        font-size: 2rem;
        font-weight: 400;
        line-height: 2.7rem;

        @media only screen and (max-width: 1024px) {
          width: 45rem;
        }

        @media only screen and (max-width: 520px) {
          width: 100%;
          font-size: 1.6rem;
        }
      }
    }

    &_img {
      width: 100%;
      height: auto;

      @media only screen and (max-width: 520px) {
        padding: 0rem 3rem;
        padding-bottom: 7rem;
      }

      & > img {
        width: 67rem;
        height: 64rem;

        @media only screen and (max-width: 1200px) {
          width: 57rem;
          height: 54rem;
        }

        @media only screen and (max-width: 768px) {
          width: 100%;
          height: auto;
          margin-top: 6rem;
        }

        @media only screen and (max-width: 520px) {
          margin-top: unset;
        }
      }
    }
  }
}

.sect3_deskimg {
  display: block;

  @media only screen and (max-width: 520px) {
    display: none;
  }
}

.sect3_mobimg {
  display: none;

  @media only screen and (max-width: 520px) {
    display: block;
  }
}
