.more {
  max-width: 144rem;
  margin: 0 auto;
  height: auto;
  padding: 6.5rem 12rem;

  @media only screen and (max-width: 1024px) {
    padding: 6.5rem 8rem;
  }

  @media only screen and (max-width: 768px) {
    padding: 6.5rem 5rem;
  }

  @media only screen and (max-width: 520px) {
    padding: 7rem 3rem;
  }

  &_heading {
    text-align: center;
    color: #1b1a41;
    font-family: "Space Grotesk";
    font-size: 4.2rem;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.824px;
    margin-bottom: 2.4rem;
    margin-bottom: 5rem;
  }

  &_divs {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 5rem;

    &_main {
      display: flex;
      gap: 5rem;

      @media only screen and (max-width: 520px) {
        flex-direction: column;
      }
    }

    &_div1 {
      grid-area: 1/1/2/2;
      background: #f8f9fc;
      width: auto;
      height: 39.2rem;
      padding: 4.2rem 8rem 4.2rem 3.5rem;
      border-radius: 1.2rem;
      -webkit-border-radius: 1.2rem;
      -moz-border-radius: 1.2rem;
      -ms-border-radius: 1.2rem;
      -o-border-radius: 1.2rem;
      position: relative;
      overflow: hidden;

      @media only screen and (max-width: 520px) {
        padding: 4.2rem 3.5rem 4.2rem 3.5rem;
      }

      & > p {
        color: #1b1a41;
        font-size: 2rem;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.4px;
        margin-bottom: 1.5rem;
      }

      & > small {
        font-size: 1.5rem;
      }

      &_icon {
        position: absolute;
        bottom: -0.5rem;
        right: 0rem;

        @media only screen and (max-width: 768px) {
          right: -6rem;
        }

        & > svg {
          @media only screen and (max-width: 768px) {
            height: 15rem;
          }
        }
      }
    }

    &_div2 {
      grid-area: 1/2/2/3;
      background: #f8f9fc;
      width: auto;
      height: 39.2rem;
      padding: 4.2rem 8rem 4.2rem 3.5rem;
      border-radius: 1.2rem;
      position: relative;

      @media only screen and (max-width: 520px) {
        padding: 4.2rem 3.5rem 4.2rem 3.5rem;
      }

      & > p {
        color: #1b1a41;
        font-size: 2rem;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.4px;
        margin-bottom: 1.5rem;
      }

      & > small {
        font-size: 1.5rem;
      }

      &_icon {
        position: absolute;
        bottom: -0.5rem;
        right: 0rem;

        @media only screen and (max-width: 768px) {
          right: -2rem;
        }

        & > svg {
          @media only screen and (max-width: 768px) {
            height: 15rem;
          }
        }
      }
    }

    &_div3 {
      background: #f8f9fc;
      padding-left: 3.5rem;
      padding-top: 11rem;
      border-radius: 1.2rem;
      position: relative;
      overflow: hidden;
      width: 100%;
      height: 37rem;

      @media only screen and (max-width: 520px) {
        height: 58rem;
        padding: 4.2rem 3.5rem;
      }

      & > p {
        color: #1b1a41;
        font-size: 2rem;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.4px;
        margin-bottom: 1.5rem;
      }

      & > small {
        font-size: 1.5rem;
        width: 56.2rem;
        display: block;

        @media only screen and (max-width: 1200px) {
          max-width: 40rem;
        }

        @media only screen and (max-width: 768px) {
          max-width: 30rem;
        }

        @media only screen and (max-width: 520px) {
          width: 100%;
          max-width: unset;
        }
      }

      &_icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 3rem;

        @media only screen and (max-width: 768px) {
          right: -5rem;
        }

        @media only screen and (max-width: 520px) {
          bottom: 0;
          left: 50%;
          right: unset;
          transform: translateX(-50%);
          -webkit-transform: translateX(-50%);
          -moz-transform: translateX(-50%);
          -ms-transform: translateX(-50%);
          -o-transform: translateX(-50%);
        }

        & > svg {
          height: 30rem;

          @media only screen and (max-width: 768px) {
            height: 25rem;
          }
        }
      }
    }
  }
}
